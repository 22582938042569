import { Button, Snackbar } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import * as api from "../../../../../api/setup-map.api";
import { IRuntimeModel } from "../model";

interface RuntimeModelRatingKPIProps {
  setupTemplateId: string | null;
  runtimeModelId: string | undefined;
  type: "portal" | "setup";
}

function RuntimeModelRatingKPI({
  setupTemplateId,
  runtimeModelId,
  type,
}: PropsWithChildren<RuntimeModelRatingKPIProps>) {
  const [runtimeModel, setRuntimeModel] = useState<IRuntimeModel>();

  const [snackbar, setSnackbar] = React.useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState<string>("");

  useEffect(() => {
    let intervalId: any;
    const fetchData = () => {
      // setIsLoading(true);
      if (runtimeModelId) {
        // Runtime model detail
        api
          .getRuntimeModelDetail(runtimeModelId, setupTemplateId)
          .then((res) => {
            // console.log(res);
            if (res) {
              setRuntimeModel(res);
            }
          })
          .catch((err) => {
            console.log(err);

            setSnackbarMsg("Something went wrong");
            setSnackbar(true);
          })
          .finally(() => {
            // setIsLoading(false);
          });
      }
    };

    if (runtimeModelId) {
      fetchData();
    }

    intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runtimeModelId]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: type === "portal" ? 60 : 160,
          left: 10,
        }}
        className="pl-2"
      >
        <div className="my-1">
          <p className="leading-normal font-semibold text-sm">
            Model Rating KPIs
          </p>
          <div className="grid grid-cols-2 gap-3 pl-3 pb-5">
            <div className="text-sm flex flex-col">
              <p>Model Name</p>
              <p>Rating</p>
              <p>Total Rated vs Total event</p>
              <p>Alert Threshold Value %</p>
              <p>Alert Threshold Count</p>
            </div>

            <div className="text-sm flex flex-col">
              <b>{runtimeModel?.name || "-"}</b>
              <b>{runtimeModel?.ratingCount || "-"}</b>
              <b>
                {(runtimeModel?.ratingCount ?? "-") +
                  " / " +
                  (runtimeModel?.totalCount ?? "-")}
              </b>
              <b>{runtimeModel?.alertThresholdValue ?? "-"}</b>
              <b>{runtimeModel?.alertThresholdRatingCount ?? "-"}</b>
            </div>
          </div>
        </div>
      </div>

      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={3000}
        message={snackbarMsg}
        open={snackbar}
        onClose={() => setSnackbar(false)}
        action={
          <>
            <Button color="secondary" onClick={() => setSnackbar(false)}>
              <CloseIcon />
            </Button>
          </>
        }
      />
    </>
  );
}

export default RuntimeModelRatingKPI;
