import React, { PropsWithChildren } from "react";
import ReactJson from "react-json-view";

interface ILogLinePreviewProps {
  logString: string;
}

function LogLinePreview({
  logString,
}: PropsWithChildren<ILogLinePreviewProps>) {
  // const [isJson, setIsJson] = useState(false);

  const isValidJson = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return isValidJson(logString) ? (
    <ReactJson
      theme="bright"
      iconStyle="triangle"
      enableClipboard={false}
      collapsed={1}
      style={{ fontSize: "12px" }}
      src={JSON.parse(logString)}
    />
  ) : (
    <div>{logString}</div>
  );
}

export default LogLinePreview;
