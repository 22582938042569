import axios, { AxiosInstance } from "axios";
import getConfigs from "../configService";

const createClient = async () => {
  try {
    // Fetch configuration settings asynchronously
    const configs = await getConfigs();

    // Create Axios client with the fetched configuration settings
    const client = axios.create({
      baseURL: configs?.REACT_APP_API_BASE_URL,
      headers: { Accept: "application/json" },
      // Add other Axios configurations as needed
    });

    return client;
  } catch (error) {
    console.error("Error fetching configuration settings:", error);
    throw error;
  }
};

const request = <T>(options: any, client: AxiosInstance): any => {
  const onSuccess = (response: any) => {
    return response.data as T;
  };

  const onError = (error: any) => {
    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

const jwtToken = () => localStorage.getItem("token");

export const get = async <T>(url: string): Promise<T> => {
  // Create Axios client by calling createClient
  const client = await createClient();

  return request(
    {
      url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
      },
    },
    client
  );
};

export const post = async <T>(url: string, data: any = null): Promise<T> => {
  // Create Axios client by calling createClient
  const client = await createClient();

  return request(
    {
      url,
      method: "POST",
      data,
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
      },
    },
    client
  );
};

export const put = async <T>(url: string, data: any = null): Promise<T> => {
  // Create Axios client by calling createClient
  const client = await createClient();

  return request(
    {
      url,
      method: "PUT",
      data,
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
      },
    },
    client
  );
};

export const del = async <T>(url: string): Promise<T> => {
  // Create Axios client by calling createClient
  const client = await createClient();

  return request(
    {
      url,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${jwtToken()}`,
      },
    },
    client
  );
};

export default request;
