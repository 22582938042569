import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { RootState } from "../store";
import RuntimeModelFlowForPortal from "./setup-editor/components/runtime-models-drawer/runtime-model-flow/RuntimeModelFlowForPortal";
import OperationalDataFlowForPortal from "./setup-editor/components/runtime-models-drawer/runtime-model-flow/OperationalDataFlowForPortal";

const AuthPage = lazy(() => import("./auth/AuthPage"));
const SetupEditorPage = lazy(() => import("./setup-editor/SetupEditorPage"));
const TemplateEditorPage = lazy(
  () => import("./template-editor/TemplateEditorPage")
);

function AppRouterSetup() {
  const userInfo = useSelector((state: RootState) => state.userInfo);

  return (
    <Routes>
      {userInfo.accessToken && (
        <>
          <Route
            path="/template-editor/:templateId"
            element={
              <Suspense>
                <TemplateEditorPage />
              </Suspense>
            }
          />
          <Route
            path="/template-editor"
            element={
              <Suspense>
                <TemplateEditorPage />
              </Suspense>
            }
          />
          <Route
            path="/setup-editor/:setupId"
            element={
              <Suspense>
                <SetupEditorPage />
              </Suspense>
            }
          />
          <Route
            path="/runtime-model/:runtimeModelId"
            element={
              <Suspense>
                <RuntimeModelFlowForPortal />
              </Suspense>
            }
          />
          <Route
            path="/operational-data/:operationalDataId"
            element={
              <Suspense>
                <OperationalDataFlowForPortal />
              </Suspense>
            }
          />
        </>
      )}
      <Route
        caseSensitive
        path="*"
        element={
          <Suspense>
            <AuthPage />
          </Suspense>
        }
      />
    </Routes>
  );
}

export default AppRouterSetup;
