import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edge, Node } from "reactflow";
import { ITemplateResponsePayload } from "../../models/template-detail.model";
import { IClassObject, IEdgeData } from "../../models/template-editor-model";

export interface IFlowMap {
  id: string;
  name: string;
  edges: Edge<IEdgeData>[];
}

export interface ITemplateState {
  id: string;
  name: string;
  description: string;
  activeFlowMap: IFlowMap | null;
  defaultFlowMap: IFlowMap | null;
  // flow maps
  flowMaps: IFlowMap[];
  nodes: Node<IClassObject>[];
  edges: Edge<IEdgeData>[];
  nodePosition: number;
}

const initialState: ITemplateState = {
  id: "",
  description: "",
  name: "",
  activeFlowMap: null,
  defaultFlowMap: null,
  flowMaps: [],
  nodes: [],
  edges: [],
  nodePosition: 100,
};

// create slice

const templateStateSlice = createSlice({
  name: "templateState",
  initialState,
  reducers: {
    initializeTemplate: (
      state,
      action: PayloadAction<Partial<ITemplateState>>
    ) => {
      state.name = action.payload.name || "";
      state.description = action.payload.description || "";
    },

    addFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      state.flowMaps.push(action.payload);
      state.edges = action.payload.edges;
      state.activeFlowMap = action.payload;

      // set default flow
      if (state.flowMaps.length === 1) {
        state.defaultFlowMap = state.flowMaps[0];
      }
    },

    updateFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      const fMapIndex = state.flowMaps.findIndex(
        (fm) => fm.id === action.payload.id
      );
      state.flowMaps[fMapIndex].edges = state.edges;
      state.activeFlowMap = state.flowMaps[fMapIndex] || null;
    },

    removeFlowMap: (state, action: PayloadAction<string>) => {
      const flowmapsWithoutId = state.flowMaps.filter(
        (fm) => fm.id !== action.payload
      );

      state.flowMaps = flowmapsWithoutId;
      if (state.nodes.length === 0) {
        state.activeFlowMap = null;
        state.defaultFlowMap = null;
        state.flowMaps = [];
      }
    },

    addTemplateNode: (state, action: PayloadAction<Node<IClassObject>>) => {
      state.nodes.push(action.payload);
    },

    updateNode: (state, action: PayloadAction<Node<IClassObject>>) => {
      const nodeIndex = state.nodes.findIndex(
        (node) => node.id === action.payload.id
      );

      state.nodes[nodeIndex] = action.payload;
    },

    deleteNodeAndAssociatedEdges: (
      state,
      action: PayloadAction<Node<IClassObject>>
    ) => {
      const nodeIndex = state.nodes.findIndex(
        (node) => node.id === action.payload.id
      );

      state.nodes.splice(nodeIndex, 1);

      // node associated edges cleanup
      const edgesToDelete = state.edges.filter(
        (edge) =>
          edge.source === action.payload.id || edge.target === action.payload.id
      );

      edgesToDelete.forEach((e) => {
        const edgeIndex = state.edges.findIndex((edge) => edge.id === e.id);
        state.edges.splice(edgeIndex, 1);
      });

      if (state.nodes.length === 0) {
        state.activeFlowMap = null;
        state.defaultFlowMap = null;
        state.flowMaps = [];
      }
    },

    addTemplateEdge: (state, action: PayloadAction<Edge<IEdgeData>>) => {
      state.edges.push(action.payload);
    },

    deleteEdge: (state, action: PayloadAction<Edge<IEdgeData>>) => {
      const edgeIndex = state.edges.findIndex(
        (edge) => edge.id === action.payload.id
      );

      state.edges.splice(edgeIndex, 1);
    },

    setTemplateActiveFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      state.activeFlowMap = action.payload || null;
      const fMap = state.flowMaps.find((fm) => fm.id === action.payload.id);

      if (fMap) {
        state.edges = fMap.edges || [];
      }
    },

    setTemplateDefaultFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      state.defaultFlowMap = action.payload || null;
      // const fMap = state.flowMaps.find((fm) => fm.id === action.payload.id);

      // if (fMap) {
      //   state.edges = fMap.edges || [];
      // }
    },

    setTemplateAllStates: (
      state,
      action: PayloadAction<ITemplateResponsePayload>
    ) => {
      state.id = action.payload.id;

      state.name = action.payload.name;

      state.description = action.payload.description;

      state.flowMaps = action.payload.flowMaps ? action.payload.flowMaps : [];

      // performing sorting in nodes endpoints for render endpoints on the the basis of presentationRank value
      if (action.payload.nodes.length) {
        const rawNodes = action.payload.nodes;
        const updatedNodes = rawNodes.map((rawNode: Node<IClassObject>) => {
          const { data: node } = rawNode;
          const endpoints = [...node.endpoints];

          const sortedEndpoints = endpoints.sort((a, b) => {
            if (a.presentationRank === b.presentationRank) {
              return a.label.localeCompare(b.label);
            }
            return a.presentationRank - b.presentationRank;
          });

          const appEndpoints = sortedEndpoints.filter(
            (ep) => ep.type === "application_end_points"
          );
          const sysEndpoints = sortedEndpoints.filter(
            (ep) => ep.type === "system_end_points"
          );

          const updatedEndpoints = [...sysEndpoints, ...appEndpoints];

          const updatedNodeData = {
            ...node,
            endpoints: updatedEndpoints,
          };

          const nodeWithUpdatedData = {
            ...rawNode,
            data: updatedNodeData,
          };

          return nodeWithUpdatedData;
        });

        state.nodes = updatedNodes;
      } else {
        state.nodes = [];
      }

      if (state.flowMaps.length) {
        const fMap = state.flowMaps.find(
          (fm) => fm.id === action.payload.activeFlowMapId
        );

        if (fMap) {
          state.activeFlowMap = fMap;
          state.edges = fMap.edges || [];
        }

        // setting default flow map
        if (action.payload.defaultFlowMapId) {
          const dFMap = state.flowMaps.find(
            (fm) => fm.id === action.payload.defaultFlowMapId
          );

          if (dFMap) {
            state.defaultFlowMap = dFMap;
          }
        }
      }
    },

    setTemplateId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },

    setNodePosition: (state, action: PayloadAction<number>) => {
      state.nodePosition = action.payload;
    },
  },
});

// Action creator and reducer exports
export const {
  addFlowMap,
  initializeTemplate,
  removeFlowMap,
  addTemplateNode,
  updateNode,
  deleteNodeAndAssociatedEdges,
  addTemplateEdge,
  deleteEdge,
  setTemplateActiveFlowMap,
  updateFlowMap,
  setTemplateAllStates,
  setTemplateId,
  setTemplateDefaultFlowMap,
  setNodePosition,
} = templateStateSlice.actions;

export default templateStateSlice.reducer;
