import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSagas from "./sagas";
import userInfoReducer from "./features/userInfoSlice";
import templateReducer from "./features/templateSlice";
import setupReducer from "./features/setupSlice";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    templateState: templateReducer,
    setupState: setupReducer,
  },
  enhancers: [applyMiddleware(sagaMiddleware)],
});

// WARNING: Very important to run sagaMiddleware after store configurations
sagaMiddleware.run(rootSagas);

// Infer the 'RootState' and 'AppDispatch' types from the store
export type RootState = ReturnType<typeof store.getState>;

// Inferred type
export type AppDispatch = typeof store.dispatch;

// usage example here
// https://redux-toolkit.js.org/tutorials/quick-start#use-redux-state-and-actions-in-react-components
