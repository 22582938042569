// src/configService.ts
export interface IConfigs {
  REACT_APP_API_BASE_URL: string;
  REACT_APP_MQTT_URL: string;
}

const getConfigs = async (): Promise<IConfigs | null> => {
  try {
    const response = await fetch("/app-config.json");
    const data: IConfigs = await response.json();
    return data;
  } catch (error) {
    console.error("Error loading configs:", error);
    return null;
  }
};

export default getConfigs;
