import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactFlow, { Node, Background, Edge, Controls } from "reactflow";

import RuntimeModelFlowNode from "./RuntimeModelFlowNode";
import {
  IClassObject,
  IEdgeData,
  NodeLayout,
  denormalizedNodeTypes,
} from "../../../../../models/template-editor-model";
import DataEdge from "../../../../../components/canvas_custom_nodes/DataEdge";
import { IFlowMap } from "../../../../../store/features/templateSlice";
import { Box, Button, Grid, LinearProgress, Snackbar } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import * as api from "../../../../../api/setup-map.api";
import { useDispatch } from "react-redux";
import { updateRTModelFlowNodes } from "../../../../../store/features/setupSlice";

const nodeTypes: any = {
  [denormalizedNodeTypes(NodeLayout.SOURCE)]: RuntimeModelFlowNode,
  [denormalizedNodeTypes(NodeLayout.SOURCE_SINK)]: RuntimeModelFlowNode,
  [denormalizedNodeTypes(NodeLayout.SINK)]: RuntimeModelFlowNode,
  [denormalizedNodeTypes(NodeLayout.OTHERS)]: RuntimeModelFlowNode,
};

const edgeTypes = {
  dataEdge: DataEdge,
};

function OperationalDataFlowForPortal() {
  const [selectedTemplate, setSelectedTemplate] = React.useState<string>("");
  const [flowEdges, setFlowEdges] = useState<Edge<IEdgeData>[]>([]);
  const [flowNodes, setFlowNodes] = useState<Node<IClassObject>[]>([]);
  const [flowMaps, setFlowMaps] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [snackbar, setSnackbar] = React.useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState<string>("");
  const [flowConfigs, setFlowConfigs] = useState<any[]>([]);

  const { operationalDataId } = useParams<{ operationalDataId: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    if (operationalDataId) {
      api
        .getOperationalDataFlow(operationalDataId)
        .then((res) => {
          if (res.data.length) {
            const routeTemplateJson = res.data[0].routeTemplateJson;
            if (routeTemplateJson) {
              const parsedRouteTemplateJson = JSON.parse(routeTemplateJson);
              setFlowMaps([parsedRouteTemplateJson]);
            } else {
              setFlowMaps([]);
              setSnackbarMsg("Flow not available");
              setSnackbar(true);
            }
          } else {
            setFlowMaps([]);
            setSnackbarMsg("Flow not available");
            setSnackbar(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setFlowMaps([]);
          setSnackbarMsg("Something went wrong");
          setSnackbar(true);
        })
        .finally(() => {
          setIsLoading(false);
          setSelectedTemplate("");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operationalDataId]);

  useEffect(() => {
    if (flowMaps) {
      if (flowMaps.length) {
        handleTemplateChange(flowMaps[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowMaps]);

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId);
    const sTemplate = flowMaps.find((flow) => flow.id === templateId);
    if (sTemplate) {
      const parsedFlow = sTemplate.template;
      if (parsedFlow) {
        setFlowNodes(parsedFlow.nodes);
        setFlowConfigs(parsedFlow.flowModelConfigurations);
        // console.log(parsedFlow.nodes);
        if (parsedFlow.flowMaps.length) {
          const sFlowMap = parsedFlow.flowMaps.find(
            (fMap: IFlowMap) => fMap.id === parsedFlow.activeFlowMapId
          );
          if (sFlowMap) {
            const nodes = parsedFlow.nodes;
            // eslint-disable-next-line array-callback-return
            const updatedEdges = sFlowMap.edges.map((ed: any) => {
              const sourceNode = nodes.find(
                (node: Node) => node.id === ed.source
              );
              const targetNode = nodes.find(
                (node: Node) => node.id === ed.target
              );
              const tempEd = { ...ed };

              if (sourceNode && targetNode) {
                const sHandle = sourceNode?.data.endpoints.find(
                  (ep: Edge) => ep.id === ed.sourceHandle
                );
                const tHandle = targetNode?.data.endpoints.find(
                  (ep: Edge) => ep.id === ed.targetHandle
                );

                if (sHandle && tHandle) {
                  const markerEnd = { ...tempEd.markerEnd };
                  const isBothConnected =
                    sHandle?.state === "connected" &&
                    tHandle?.state === "connected";
                  if (isBothConnected) {
                    dispatch(updateRTModelFlowNodes(sourceNode.data.id));
                    dispatch(updateRTModelFlowNodes(targetNode.data.id));
                  }
                  const edgeDashedArray = isBothConnected ? "15 4" : "0 0";
                  return {
                    ...tempEd,
                    animated: isBothConnected,
                    style: {
                      stroke: isBothConnected ? "green" : "#F0F0F0",
                      // strokeDasharray: "15 3",
                      strokeDasharray: edgeDashedArray,
                      strokeLinecap: "round",
                    },
                    markerEnd: {
                      ...markerEnd,
                      color: isBothConnected ? "green" : "#F0F0F0",
                      strokeWidth: isBothConnected ? 3 : 2,
                    },
                  };
                }
              } else {
                return tempEd;
              }
            });
            setFlowEdges(updatedEdges);
          }
        }
      }
    }
  };

  return (
    <>
      <Grid className="mx-0.5 mt-1">
        <div className="flex space-between items-center">
          <div className="flex flex-col">
            <Box sx={{ minWidth: 470 }} className="ml-2">
              {isLoading && (
                <Box className="px-1 py-1">
                  <LinearProgress />
                </Box>
              )}
              {/* <FormControl
                fullWidth
                variant="filled"
                size="small"
                style={{ minHeight: "auto", padding: "0", margin: "0" }}
              >
                <InputLabel id="demo-simple-select-label">Template</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedTemplate}
                  label="Template"
                  disabled={true}
                  onChange={(event) => handleTemplateChange(event.target.value)}
                  className="text-xs"
                >
                  {flowMaps &&
                    flowMaps.map((flow, index) => (
                      <MenuItem key={flow.id + index} value={flow.id}>
                        <span className="text-xs">{flow.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
            </Box>
          </div>
        </div>
      </Grid>

      <div className="flex">
        {/* <p className="font-medium my-1">Flow</p> */}
        {/* <RuntimeModelFlagValues
          flowConfigurations={flowConfigs}
          type="portal"
        /> */}

        <ReactFlow
          nodes={flowNodes}
          edges={flowEdges}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView={true}
          minZoom={0.4}
          nodesDraggable={true}
          nodesConnectable={false}
          style={{
            minHeight: "calc(80vh - -40px)",
            paddingTop: "0px",
          }}
        >
          <Controls />
          <Background />
        </ReactFlow>
      </div>

      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={3000}
        message={snackbarMsg}
        open={snackbar}
        onClose={() => setSnackbar(false)}
        action={
          <>
            <Button color="secondary" onClick={() => setSnackbar(false)}>
              <CloseIcon />
            </Button>
          </>
        }
      />
    </>
  );
}

export default OperationalDataFlowForPortal;
