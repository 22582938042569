import React, { useEffect, useState } from "react";
import { mdiViewStreamOutline } from "@mdi/js";
import Icon from "@mdi/react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
  IconButton,
  Grid,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getBezierPath } from "reactflow";
import { connect } from "mqtt";

import CloseIcon from "@mui/icons-material/Close";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TopicIcon from "@mui/icons-material/Topic";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { RootState } from "../../store";
import { ISetupState } from "../../store/features/setupSlice";
import uniqueId from "../../utils/unique-id-generator.utils";
import * as api from "../../api/setup-map.api";
import { ProtocolType } from "../../models/template-editor-model";
import LinearProgress from "@mui/material/LinearProgress";
import avro from "avsc";
import LogLine from "./data_logs/LogLine";
import LogLinePreview from "./data_logs/LogLinePreview";
import { ILogModel } from "./data_logs/model";
import getConfigs, { IConfigs } from "../../configService";

const foreignObjectSize = 40;

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  selected,
  source,
  target,
}: any) {
  const [edgePath, labelX, labelY]: any = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const setupState = useSelector((state: RootState) => state.setupState);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  // const sEdge = setupState.selectedEdge;

  const [dataDialog, setDataDialog] = React.useState(false);
  const [topic, setTopic] = React.useState("");
  const [title, setTitle] = React.useState("");
  // const [edgeStream, setEdgeStream] = React.useState("");
  // const [protocol, setProtocol] = React.useState("");
  const [schema, setSchema] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSchemaLoaded, setIsSchemaLoaded] = React.useState(false);

  const [buffer, setBuffer] = React.useState<ILogModel[]>([]);
  const [bufferCount, setBufferCount] = React.useState<number>(20);

  const [snackbar, setSnackbar] = React.useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isPause, setIsPause] = React.useState(false);
  const [logLine, setLogLine] = useState<ILogModel | null>(null);
  const [configs, setConfigs] = useState<IConfigs | null>(null);

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const configs = await getConfigs();
        if (configs) {
          setConfigs(configs);
        }
      } catch (error) {
        console.error("Error fetching configurations:", error);
      }
    };

    fetchConfigs();
  }, []);

  // const logViewer: any = React.useRef();

  // Listen edge flag to open the data dialog
  useEffect(() => {
    if (selected) {
      onEdgeClick(id, setupState);
      setDataDialog(true);
    } else {
      setDataDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  // calculating source and sink endpoint label
  // useEffect(() => {
  //   if (id) {
  //     const sourceNode = setupState.nodes.find((n) => n.id === source);
  //     const targetNode = setupState.nodes.find((n) => n.id === target);

  //     if (sourceNode && targetNode) {
  //       const edgeId = id;
  //       // removing the source and target node id
  //       const trimEdgeString = edgeId
  //         .replace("reactflow__edge-", "")
  //         .replace(source, "")
  //         .replace(target, ",");

  //       // handle ids in array and will separate
  //       const edgeHandleIds = trimEdgeString.split(",");
  //       const sHandleId = edgeHandleIds[0].slice(0, -1);
  //       const tHandleId = edgeHandleIds[1];

  //       if (sHandleId && tHandleId) {
  //         // get source endpoint name/label
  //         const sourceEndpoint = sourceNode.data.endpoints.find(
  //           (ep) => ep.id === sHandleId
  //         );

  //         // get sink endpoint name/label
  //         const sinkEndpoint = targetNode.data.endpoints.find(
  //           (ep) => ep.id === tHandleId
  //         );

  //         if (!sourceEndpoint || !sinkEndpoint) {
  //           setEdgeStream("");
  //           return;
  //         } else {
  //           setEdgeStream(`${sourceEndpoint.label}/${sinkEndpoint.label}`);
  //         }
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  const onEdgeClick = (id: any, setupState: ISetupState, evt?: any) => {
    if (evt) {
      evt.stopPropagation();
    }

    const selectedEdge = setupState.edges.find((e: any) => e.id === id);
    if (!selectedEdge) {
      return;
    }

    const selectedSourceNode = setupState.nodes.find(
      (n) => n.id === selectedEdge.source
    );

    const selectedTargetNode = setupState.nodes.find(
      (n) => n.id === selectedEdge.target
    );

    if (
      !selectedSourceNode?.data.instance ||
      !selectedTargetNode?.data.instance
    ) {
      return;
    }

    // get source endpoint name/label
    const selectedSourceEndpoint = selectedSourceNode.data.endpoints.find(
      (ep) => ep.id === selectedEdge.sourceHandle
    );

    // get sink endpoint name/label
    const selectedSinkEndpoint = selectedTargetNode.data.endpoints.find(
      (ep) => ep.id === selectedEdge.targetHandle
    );

    if (!selectedSourceEndpoint || !selectedSinkEndpoint) {
      return;
    }

    const sProtocol = selectedSourceEndpoint.protocol.find(
      (p) => p === selectedEdge.data?.sourceProtocol
    );

    const tProtocol = selectedSinkEndpoint.protocol.find(
      (p) => p === selectedEdge.data?.sinkProtocol
    );

    if (!sProtocol || !tProtocol) {
      return;
    }

    // setting MQTT topic
    if (tProtocol === ProtocolType.MQTT && tProtocol === ProtocolType.MQTT) {
      // setProtocol(ProtocolType.MQTT);
      setTopic(
        `${userInfo.accountId}/${selectedSourceNode.data.type}/${
          selectedSourceNode?.data?.instance?.id || ""
        }/${selectedSourceEndpoint?.label}`
      );
    }

    // setting kafka topic
    if (tProtocol === ProtocolType.KAFKA && tProtocol === ProtocolType.KAFKA) {
      // setProtocol(ProtocolType.KAFKA);
      setTopic(
        `${userInfo.accountId}.${selectedSourceNode.data.type}.${
          selectedSourceNode?.data?.instance?.id || ""
        }.${selectedSourceEndpoint?.label}`
      );
    }

    setTitle(
      `${selectedSourceNode.data.instance?.label} (${
        selectedSourceNode.data.instance?.type
      }) > ${selectedSourceEndpoint?.label} (${sProtocol || tProtocol})`
    );

    if (
      selectedSinkEndpoint.dataSchema !== null &&
      selectedSinkEndpoint.dataSchema !== "NoSchema"
    ) {
      setIsLoading(true);
      // fetch schema
      const payload = {
        dataSchema: selectedSinkEndpoint.dataSchema,
      };
      api
        .getSetupSchema(setupState.id, payload)
        .then((res) => {
          setSchema(res.dataSchema);
          setIsLoading(false);
          setIsSchemaLoaded(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setSchema(undefined);
    }

    setDataDialog(true);
  };

  const handleOnCloseDataDialog = () => {
    setDataDialog(false);
    setIsSchemaLoaded(false);
    setTopic("");
    setBuffer([]);
    setIsPause(false);
    setLogLine(null);
  };

  const handleOnPause = () => {
    setIsPause(!isPause);
    setIsSchemaLoaded(!isSchemaLoaded);
    setMessage(
      isPause
        ? ("Log stream paused" as string)
        : ("Log stream resume" as string)
    );
    setSnackbar(true);
  };

  const handleOnDownloadLogs = () => {
    setIsSchemaLoaded(false);
    setIsPause(true);

    const dataBufferToDownload = buffer.map((obj) => {
      return JSON.parse(obj.logString);
    });

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(dataBufferToDownload)
    )}`;

    const link = document.createElement("a");
    link.href = jsonString;
    link.download = `${topic}.json`;

    link.click();
    // setMessage("Log stream download.");
    // setSnackbar(true);
    handleOnCloseDataDialog();
  };

  React.useEffect(() => {
    if (buffer.length > 0) {
      // removing last element when it will cross limit
      if (buffer.length > bufferCount) {
        const trimBuffer = buffer.slice(0, -1);
        setBuffer([...trimBuffer]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buffer]);

  useEffect(() => {
    if (!isSchemaLoaded) {
      // set buffer for pause/resume
      if (isPause) {
        const tempBuffer = buffer;
        setBuffer([...tempBuffer]);
      }
      return;
    }

    if (userInfo.email === "") {
      return;
    }

    if (configs) {
      const client = connect(configs.REACT_APP_MQTT_URL || "", {
        clientId: "data-listener" + userInfo.email + "-" + uniqueId(),
        username: userInfo.email, //userInfo.email,
        password: userInfo.accessToken,

        protocolVersion: 4,
        connectTimeout: 4000,
        clean: true,
        rejectUnauthorized: false, // ignore ssl error
      });

      client.on("connect", () => {
        console.log("MQTT connected");

        // subscribe to topic
        client.subscribe(topic, { qos: 2 }, (err) => {
          if (err) {
            console.log(err);
          } else {
            console.log("MQTT subscribed: " + topic);
          }
        });
      });

      client.on("error", (err) => {
        console.log(err);
      });

      // listen to MQTT messages

      client.on("message", (topic, message) => {
        console.log(`MQTT message received: ${topic}`);

        if (!schema) {
          // adding timestamp to received message
          const logObj = {
            logString: message.toString(),
            timeTag: new Date().toISOString(),
          };
          setBuffer((oldBuffer) => [logObj, ...oldBuffer]);
        }

        if (schema) {
          const type = avro.Type.forSchema(schema);
          const val = type.fromBuffer(message);

          // adding timestamp to received message
          const logObj = {
            logString: val.toString(),
            timeTag: new Date().toISOString(),
          };
          setBuffer((oldBuffer) => [logObj, ...oldBuffer]);
        }
      });

      return () => {
        try {
          console.log("MQTT disconnecting");
          client.unsubscribe(topic);
          client.end();
          if (!isPause) {
            setBuffer([]);
          }
        } catch (err) {
          console.log(err);
        }
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSchemaLoaded, configs]);

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={foreignObjectSize}
        height={foreignObjectSize}
        x={labelX - foreignObjectSize / 2}
        y={labelY - foreignObjectSize / 2}
        className="edge-button-foreign-object"
        requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div>
          <button className="edge-button">
            <Icon path={mdiViewStreamOutline} size={0.6} />
          </button>
        </div>
      </foreignObject>

      <Dialog
        open={dataDialog}
        onClose={handleOnCloseDataDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={logLine ? "lg" : "md"}
      >
        {/* dialog header Section */}

        <div className="pl-2 pt-2 pr-2 flex justify-between item-center">
          <div>
            <span className="font-medium text-l break-all p-1">{title}</span>
          </div>

          <div>
            <Tooltip title="Close">
              <IconButton
                aria-label="Close"
                size="small"
                className="mr-1"
                onClick={handleOnCloseDataDialog}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <DialogContent
          style={{
            paddingLeft: "11px",
            paddingRight: "6px",
            paddingBottom: "3px",
            paddingTop: "0px",
          }}
        >
          {isLoading && (
            <Box sx={{ width: "100%" }} className="my-2">
              <LinearProgress />
            </Box>
          )}

          {/* Logs section */}
          {!isLoading && (
            <div>
              <Grid container spacing={0.5} xs={12}>
                <Grid item xs={logLine ? 8 : 12}>
                  {/* log header */}

                  <Box className="font-mono text-sm flex justify-between item-center bg-gray-200 sticky top-0">
                    <div className="flex">
                      <small className="ml-1">Timestamp</small>
                      <small className="ml-28">Message</small>
                    </div>
                  </Box>

                  {/* logs */}
                  <div style={{ height: "490px" }} className="overflow-y-auto">
                    {buffer &&
                      buffer.map((line, index) => (
                        <LogLine
                          key={line.timeTag}
                          className={
                            line.timeTag === logLine?.timeTag
                              ? `bg-blue-200`
                              : `border-b`
                          }
                          logString={line.logString}
                          onClickLine={() => setLogLine(line)}
                          timeTag={line.timeTag}
                        />
                      ))}
                  </div>
                </Grid>

                <Grid item xs={logLine ? 4 : 0}>
                  {logLine && (
                    <>
                      <div
                        className="overflow-y-auto bg-black"
                        style={{ height: "506px" }}
                      >
                        <div className="pt-1 flex justify-between item-center">
                          <div className="ml-1">
                            {logLine && (
                              <small className="text-gray-400 ml-1">
                                {logLine.timeTag}
                              </small>
                            )}
                          </div>
                          <div>
                            <Tooltip title="Copy to clipboard">
                              <IconButton
                                aria-label="Close"
                                size="small"
                                className="mr-1"
                                onClick={() =>
                                  navigator.clipboard.writeText(
                                    logLine.logString
                                  )
                                }
                              >
                                <ContentCopyIcon
                                  fontSize="small"
                                  className="text-white"
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Close Preview">
                              <IconButton
                                aria-label="Close"
                                size="small"
                                className="mr-1"
                                onClick={() => setLogLine(null)}
                              >
                                <CloseIcon
                                  fontSize="small"
                                  className="text-white"
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                        <div>
                          {logLine && (
                            <LogLinePreview logString={logLine.logString} />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </Grid>
              </Grid>
              <></>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", verticalAlign: "middle" }}
        >
          <div className="ml-1">
            <Tooltip title="Click to copy topic">
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() => navigator.clipboard.writeText(`${topic}`)}
                color="primary"
              >
                <TopicIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {buffer.length ? (
              <Tooltip title="Download Stream">
                <IconButton
                  aria-label="download"
                  size="small"
                  onClick={handleOnDownloadLogs}
                  color="primary"
                >
                  <DownloadForOfflineIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <div className="mr-2">
            <FormControl sx={{ minWidth: 50 }} size="small">
              <InputLabel
                id="demo-select-small"
                style={{ fontSize: "12px", marginTop: "4px" }}
              >
                Lines
              </InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={bufferCount}
                label="Logs Lines"
                onChange={(e) => setBufferCount(e.target.value as number)}
                style={{ fontSize: "11px" }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handleOnPause} disabled={isLoading}>
              {isPause ? (
                <PlayArrowIcon fontSize="small" />
              ) : (
                <PauseIcon fontSize="small" />
              )}

              {isPause ? "Resume" : "Pause"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>

      {/* alert message */}
      <Snackbar
        open={snackbar}
        autoHideDuration={3000}
        onClose={() => setSnackbar(false)}
        message={message}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => setSnackbar(false)}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
}
