import SMoteQuattro from "../../images/s-mote-rpi3b-.png";
import ATCIOT2050 from "../../images/atc-mote-iot2050.png";
import ATCCombomoteRPI3 from "../../images/atc-mote-rpi3b-.png";
import SIMATIC127E from "../../images/SIMATIC-127E.png";
import SIMATIC227E from "../../images/SIMATIC-227E.png";
import SIMATIC427E from "../../images/SIMATIC-427E.png";
import SIMATIC520A from "../../images/SIMATIC-520A.png";
import JETSONORIN from "../../images/JETSON-ORIN.png";
import AppProcessor from "../../images/ds.png";
import INTELNUC from "../../images/INTEL-NUC.png";
import AWSEC2 from "../../images/AWS-EC2.png";
import AZURECI from "../../images/AZURE-CI.png";
import GCPCE from "../../images/GCP-CE.png";

const ModelImages: { [key: string]: string } = {
  // Devices
  "S-Mote-Quattro": SMoteQuattro,
  "ATC IOT2050": ATCIOT2050,
  "ATC Combo mote RPI3+ 3.0": ATCCombomoteRPI3,
  "ATC Combo mote RPI3+:3.0": ATCCombomoteRPI3,
  "Video-Mote RPi3B+": ATCCombomoteRPI3,
  "ATC-mote RPI3B+": ATCCombomoteRPI3,
  "3b+:video-mote": ATCCombomoteRPI3,
  "3b+:s-mote": ATCCombomoteRPI3,
  "2050-1YA2:s-mote": ATCIOT2050,
  "3b+:atc": ATCCombomoteRPI3,
  "2050-1YA2:atc": ATCIOT2050,
  "S-Mote RPI3B+": ATCCombomoteRPI3,

  //cluster nodes
  "SIMATIC-127E": SIMATIC127E,
  "SIMATIC-227E": SIMATIC227E,
  "SIMATIC-427E": SIMATIC427E,
  "SIMATIC-520A": SIMATIC520A,
  "INTEL-NUC": INTELNUC,
  "JETSON-ORIN": JETSONORIN,
  "AWS-EC2": AWSEC2,
  "AZURE-CI": AZURECI,
  "GCP-CE": GCPCE,
  AppProcessor: AppProcessor,
};

export default ModelImages;
