/* eslint-disable react/display-name */
import React, { memo } from "react";
import { Node, Handle, Position } from "reactflow";

import {
  DataDirection,
  IClassObject,
  IEndpoint,
} from "../../../../../models/template-editor-model";

import RTNonInstantiatedNode from "../../../../../components/canvas_custom_nodes/RTNonInstantiatedNode";

// import { useDispatch } from "react-redux";

const RuntimeModelFlowNode = memo<Node<IClassObject>>(
  (rawNode: Node<IClassObject>) => {
    const { data: node } = rawNode;

    const renderHandles = (
      endpoints: IEndpoint[] = [],
      position: Position = Position.Left,
      type: DataDirection,
      gap: number = 0
    ) => {
      const nodeType = type === DataDirection.IN ? "target" : "source";

      return endpoints
        .filter((ep) => ep.dataDirection === type)
        .map((ep) => {
          gap += 12;
          return (
            <Handle
              key={ep.id}
              title={`${ep.type}/${ep.label}/${ep.dataSchema}`}
              type={nodeType}
              id={ep.id}
              position={position}
              style={{
                top: ep?.type === "system_end_points" ? gap : gap + 8,
                width: ep?.type === "system_end_points" ? "11px" : "9px",
                height: ep?.type === "system_end_points" ? "7px" : "9px",
                borderRadius: ep?.type === "system_end_points" ? "2px" : "4px",
                // backgroundColor: "#60a5fa",
              }}
            ></Handle>
          );
        });
    };

    return (
      <>
        {renderHandles(node.endpoints, Position.Left, DataDirection.IN)}

        <RTNonInstantiatedNode rawNode={rawNode} />

        {renderHandles(node.endpoints, Position.Right, DataDirection.OUT, 2)}
      </>
    );
  }
);

export default RuntimeModelFlowNode;
