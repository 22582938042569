/* eslint-disable no-fallthrough */
import React, { PropsWithChildren, useState } from "react";
import Icon from "@mdi/react";
import { mdiDotsSquare, mdiBallotOutline, mdiPuzzle } from "@mdi/js";
import {
  ObjectType,
  nodeStyleClasses,
} from "../../models/template-editor-model";

import ModelImages from "./ModelImages";

interface INodeIconProps {
  type: ObjectType;
  model: string;
}

function NodeIcon({ type, model }: PropsWithChildren<INodeIconProps>) {
  const [colorClass] = useState<{ colorSolid: string; colorLight: string }>(
    nodeStyleClasses(type)
  );

  switch (type) {
    case ObjectType.DEVICE:
      return <img src={ModelImages[model]} alt={model} />;

    case ObjectType.CN_CORE:
      return <img src={ModelImages[model]} alt={model} />;

    case ObjectType.CLIENT_APP:
      // return <img src={ModelImages.ClientApp} alt={model} />;
      return <Icon path={mdiPuzzle} size={1.6} color={colorClass.colorLight} />;

    case ObjectType.PROCESSING_NODE:
    case ObjectType.INFRA_OR_EXTERNAL_NODE:
      return <img src={ModelImages.AppProcessor} alt={model} />;

    case ObjectType.APP_JOB:
      return (
        <Icon path={mdiBallotOutline} size={1} color={colorClass.colorSolid} />
      );

    default:
      return (
        <Icon path={mdiDotsSquare} size={1} color={colorClass.colorSolid} />
      );
  }
}

export default NodeIcon;
