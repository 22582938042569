import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IUserInfoState {
  accountId: string;
  accountName: string;
  clientId: string;
  clientType: string;
  clientTypeId: string;
  email: string;
  firstName: string;
  lastName: string;
  tenantAdminAccountId: string;
  tenantAdminUserId: string;
  tenantId: string;
  tenantName: string;
  userId: string;
  userType: string;
  accessToken: string;
}

const initialState: IUserInfoState = {
  accountId: "",
  accountName: "",
  clientId: "",
  clientType: "",
  clientTypeId: "",
  email: "",
  firstName: "",
  lastName: "",
  tenantAdminAccountId: "",
  tenantAdminUserId: "",
  tenantId: "",
  tenantName: "",
  userId: "",
  userType: "",
  accessToken: "",
};

// creating a slice
const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action: PayloadAction<IUserInfoState>) => {
      state.accountId = action.payload.accountId;
      state.accountName = action.payload.accountName;
      state.clientId = action.payload.clientId;
      state.clientType = action.payload.clientType;
      state.clientTypeId = action.payload.clientTypeId;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.tenantAdminAccountId = action.payload.tenantAdminAccountId;
      state.tenantAdminUserId = action.payload.tenantAdminUserId;
      state.tenantId = action.payload.tenantId;
      state.tenantName = action.payload.tenantName;
      state.userId = action.payload.userId;
      state.userType = action.payload.userType;
      state.accessToken = action.payload.accessToken;
    },

    logout: (state) => {
      state.accountId = "";
      state.accountName = "";
      state.clientId = "";
      state.clientType = "";
      state.clientTypeId = "";
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.tenantAdminAccountId = "";
      state.tenantAdminUserId = "";
      state.tenantId = "";
      state.tenantName = "";
      state.userId = "";
      state.userType = "";
      state.accessToken = "";
    },
  },
});

// Action creator and reducer exports
export const { setUserInfo, logout } = userInfoSlice.actions;

export default userInfoSlice.reducer;
