import { call, put, takeEvery } from "redux-saga/effects";
import { addFlowMap, setTemplateId } from "../../features/templateSlice";
import * as api from "../../../api/template-map.api";
import { ITemplateResponsePayload } from "../../../models/template-detail.model";

function* getTemplateDetail(_action: any) {
  try {
    yield put({ type: "USER_INFO_LOADING" });
  } catch (e: any) {
    yield put({
      type: "USER_INFO_LOADING_ERROR",
      message: e.message,
    });
  }
}

function* createTemplateWithDefaultFlow(_action: any) {
  try {
    const defaultFlow = _action.payload;
    const reqPayload = _action.requestPayload;
    let flows = [];
    flows.push(defaultFlow);

    yield put(addFlowMap(defaultFlow));

    // payload for create template backend call
    const requestPayload = {
      name: reqPayload.name,
      description: reqPayload.description,
      nodes: reqPayload.nodes,
      flowMaps: flows,
      activeFlowMapId: defaultFlow ? defaultFlow.id : "",
      previousFlowMapId: defaultFlow ? defaultFlow.id : "",
      defaultFlowMapId: defaultFlow ? defaultFlow.id : "",
    };
    const resp: ITemplateResponsePayload = yield call(
      api.createTemplate,
      requestPayload
    );
    if (resp) {
      yield put(setTemplateId(resp.id));
    }
  } catch (e: any) {}
}

export const templateMapSagas = [
  takeEvery("GET_TEMPLATE_DETAIL", getTemplateDetail),
  takeEvery("CREATE_TEMPLATE_WITH_DEFAULT_FLOW", createTemplateWithDefaultFlow),
];
