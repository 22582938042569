import React, { PropsWithChildren } from "react";

interface ILogLineProps {
  logString: string;
  className: string;
  onClickLine: (string: string) => void;

  timeTag: string;
}

function LogLine({
  logString,
  className,
  onClickLine,
  timeTag,
}: PropsWithChildren<ILogLineProps>) {
  return (
    <div
      onClick={() => onClickLine(logString)}
      style={{ fontSize: "12px" }}
      className={`pt-1 pb-1 text-gray-600 font-mono hover:cursor-pointer hover:bg-blue-50 text-ellipsis truncate ${className}`}
    >
      <span className="pr-1 font-bold">›</span>
      <small className="pr-4">{timeTag || "-"}</small>
      <small style={{ color: "#009688" }}>{logString}</small>
    </div>
  );
}

export default LogLine;
