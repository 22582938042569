import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Edge, Node } from "reactflow";
import { ISetupDetailResponse } from "../../models/setup-detail.model";
import {
  IClassObject,
  IEdgeData,
  IEndpoint,
} from "../../models/template-editor-model";
import { IFlowMap } from "./templateSlice";

export interface IClassObjectState {
  clientType: string;
  clientId: string;
  modules: Partial<{
    // app: string;
    // host: string;
    // agent: string;
    [key: string]: string;
  }>;
  endpoints?: Partial<{ [key: string]: string }>;
}

export interface IEndpointsData {
  id: string;
  endpoints: IEndpoint[];
}

export interface IClassObjectStateUpdate {
  clientType: string;
  clientId: string;
  moduleType: string;
  state: string;
  endpointId: string;
}

export interface ISetupState {
  id: string;
  name: string;
  description: string;
  activeFlowMap: IFlowMap | null;
  defaultFlowMap: IFlowMap | null;
  restoreTemplate: any;
  // flow maps
  flowMaps: IFlowMap[];
  nodes: Node<IClassObject>[];
  edges: Edge<IEdgeData>[];

  // states

  // setupState: string;
  classObjectStates: IClassObjectState[];
  setupState: string;

  setupTemplateId: string;
  rtModelFlowNodes: string[];
}

const initialState: ISetupState = {
  id: "",
  description: "",
  name: "",
  restoreTemplate: {},
  activeFlowMap: null,
  defaultFlowMap: null,
  flowMaps: [],
  nodes: [],
  edges: [],
  classObjectStates: [],
  setupState: "",
  setupTemplateId: "",
  rtModelFlowNodes: [],
};

// create slice

const setupStateSlice = createSlice({
  name: "setupState",
  initialState,
  reducers: {
    initializeSetup: (state, action: PayloadAction<Partial<ISetupState>>) => {
      state.id = action.payload.id || "";
      state.name = action.payload.name || "";
      state.description = action.payload.description || "";
    },

    addInitializingSetupState: (
      state,
      action: PayloadAction<IClassObjectState>
    ) => {
      const tempState = action.payload;
      if (state.classObjectStates.length) {
        const isExist = state.classObjectStates.find(
          (fm) => fm.clientId === action.payload.clientId
        );
        if (!isExist) {
          state.classObjectStates.push(tempState);
        }
      } else {
        state.classObjectStates.push(tempState);
      }
    },

    updateClassObjectState: (
      state,
      action: PayloadAction<IClassObjectStateUpdate>
    ) => {
      const foundClient = state.classObjectStates.findIndex(
        (o) =>
          o.clientId === action.payload.clientId &&
          o.clientType === action.payload.clientType
      );

      // setup level state updates
      if (action.payload.moduleType === "setup") {
        state.setupState = action.payload.state;
      }

      if (action.payload.moduleType !== "endpoint") {
        // update module
        if (foundClient > -1) {
          // existing client
          state.classObjectStates[foundClient].modules[
            action.payload.moduleType
          ] = action.payload.state;
        } else {
          // just insert new client
          state.classObjectStates.push({
            clientId: action.payload.clientId,
            clientType: action.payload.clientType,
            modules: {
              [action.payload.moduleType]: action.payload.state,
            },
          });
        }
      } else {
        // update endpoint state
        if (foundClient > -1) {
          // existing client
          state.classObjectStates[foundClient].endpoints = {
            ...state.classObjectStates[foundClient].endpoints,
            [action.payload.endpointId]: action.payload.state,
          };
        } else {
          // just insert new client
          state.classObjectStates.push({
            clientId: action.payload.clientId,
            clientType: action.payload.clientType,
            modules: {},
            endpoints: {
              [action.payload.endpointId]: action.payload.state,
            },
          });
        }
      }
    },

    updateNodeEndpoints: (state, action: PayloadAction<IEndpointsData>) => {
      const nodeIndex = state.nodes.findIndex(
        (n) => n.id === action.payload.id
      );

      state.nodes[nodeIndex].data.endpoints = action.payload.endpoints;
    },

    setFlowMaps: (state, action: PayloadAction<IFlowMap[]>) => {
      state.flowMaps = action.payload;
    },

    setSetupsNodes: (state, action: PayloadAction<Node<IClassObject>[]>) => {
      state.nodes = action.payload;
    },

    updateSetupsEdges: (state, action: PayloadAction<Edge<IEdgeData>[]>) => {
      state.edges = action.payload;
    },

    setActiveFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      state.activeFlowMap = action.payload || null;
      const fMap = state.flowMaps.find((fm) => fm.id === action.payload.id);

      if (fMap) {
        state.edges = fMap.edges || [];
      }
    },

    updateNode: (state, action: PayloadAction<Node<IClassObject>>) => {
      const nodeIndex = state.nodes.findIndex(
        (node) => node.id === action.payload.id
      );

      state.nodes[nodeIndex] = action.payload;
    },

    updateNodes: (state, action: PayloadAction<Node<IClassObject>[]>) => {
      state.nodes = action.payload;
    },

    updateSetupNodeInstanceInfo: (state, action: PayloadAction<any>) => {
      const { nodeId, instanceInfo } = action.payload;

      state.nodes.forEach((node) => {
        if (node.id === nodeId) {
          if (instanceInfo !== null) {
            node.data.instance = { ...instanceInfo };
          } else {
            node.data.instance = null;
          }
        }
      });
    },

    addFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      state.flowMaps.push(action.payload);
      state.edges = action.payload.edges;
      state.activeFlowMap = action.payload;
    },

    setSetupMapStateFromApiResponse(
      state,
      action: PayloadAction<ISetupDetailResponse>
    ) {
      const { restoreTemplate } = action.payload;

      state.id = action.payload.id;
      state.name = action.payload.name;
      state.description = action.payload.description;
      state.setupState = action.payload.state;
      state.setupTemplateId = action.payload.templateId;
      state.flowMaps = restoreTemplate.flowMaps;

      // performing sorting in nodes endpoints for render endpoints on the the basis of presentationRank value
      if (restoreTemplate.nodes.length) {
        const rawNodes = restoreTemplate.nodes;
        const updatedNodes = rawNodes.map((rawNode: Node<IClassObject>) => {
          const { data: node } = rawNode;
          const endpoints = [...node.endpoints];

          const sortedEndpoints = endpoints.sort((a, b) => {
            if (a.presentationRank === b.presentationRank) {
              return a.label.localeCompare(b.label);
            }
            return a.presentationRank - b.presentationRank;
          });

          const appEndpoints = sortedEndpoints.filter(
            (ep) => ep.type === "application_end_points"
          );
          const sysEndpoints = sortedEndpoints.filter(
            (ep) => ep.type === "system_end_points"
          );

          const updatedEndpoints = [...sysEndpoints, ...appEndpoints];

          const updatedNodeData = {
            ...node,
            endpoints: updatedEndpoints,
          };

          const nodeWithUpdatedData = {
            ...rawNode,
            data: updatedNodeData,
          };

          return nodeWithUpdatedData;
        });

        state.nodes = updatedNodes;
      } else {
        state.nodes = [];
      }
      state.edges = restoreTemplate.edges;
      state.restoreTemplate = action.payload.restoreTemplate;

      if (state.flowMaps.length) {
        const activeFlowMap = state.flowMaps.filter(
          (fm) => fm.id === restoreTemplate.activeFlowMapId
        );

        if (activeFlowMap.length === 1) {
          state.activeFlowMap = activeFlowMap[0];
          state.edges = activeFlowMap[0].edges;
        } else {
          state.activeFlowMap = restoreTemplate.flowMaps[0];
          state.edges = restoreTemplate.flowMaps[0].edges;
        }
        // set default flow map
        const dFlowMap = state.flowMaps.filter(
          (fm) => fm.id === restoreTemplate.defaultFlowMapId
        );

        if (dFlowMap.length) {
          state.defaultFlowMap = dFlowMap[0];
        }
      }
    },

    setSetupActiveFlowMap: (state, action: PayloadAction<IFlowMap>) => {
      state.activeFlowMap = action.payload || null;
      const fMap = state.flowMaps.find((fm) => fm.id === action.payload.id);

      if (fMap) {
        state.edges = fMap.edges || [];
        // updating the previous and selected flow maps id
        state.restoreTemplate.previousFlowMapId =
          state.restoreTemplate.activeFlowMapId;
        state.restoreTemplate.activeFlowMapId = fMap.id;
      }
    },

    updateRTModelFlowNodes: (state, action: PayloadAction<string>) => {
      // console.log(action.payload);
      if (!state.rtModelFlowNodes.includes(action.payload)) {
        state.rtModelFlowNodes.push(action.payload);
      }

      // if (!existingNodes.includes(action.payload[1])) {
      //   existingNodes.push(action.payload[1]);
      // }
      // state.rtModelFlowNodes = existingNodes;
    },
  },
});

// Action creator and reducer exports
export const {
  initializeSetup,
  setActiveFlowMap,
  setFlowMaps,
  setSetupsNodes,
  updateNode,
  updateSetupNodeInstanceInfo,
  setSetupMapStateFromApiResponse,
  setSetupActiveFlowMap,
  addInitializingSetupState,
  updateClassObjectState,
  updateNodes,
  updateNodeEndpoints,
  updateSetupsEdges,
  updateRTModelFlowNodes,
} = setupStateSlice.actions;

export default setupStateSlice.reducer;
