/* eslint-disable no-unused-vars */

export enum DataDirection {
  IN = "sink", // in
  OUT = "source", // out
}

export interface IEndpoint {
  dataDirection: DataDirection;
  id: string;
  label: string;
  protocol: string[];
  state?: string;
  dataSchema: string | null;
  type: "system_end_points" | "application_end_points";
  presentationRank: number;
  endpointType?: string;
}

export interface SupportedPlugin {
  id: string;
  name: string;
  version: string;
  endpoints: IEndpoint[];
}

export enum NodeLayout {
  SOURCE = "source",
  SOURCE_SINK = "source_sink",
  SINK = "sink",
  OTHERS = "others",
}

export enum ObjectType {
  APP_JOB = "app_jobs",
  PROCESSING_NODE = "processing_node",
  CN_CORE = "cn_core",
  DEVICE = "device",
  INFRA_OR_EXTERNAL_NODE = "infra_or_external_node",
  CLIENT_APP = "client_app",
}

export enum ProtocolType {
  KAFKA = "kafka",
  MQTT = "mqtt",
  FILE = "file",
  RABBIT_MQ = "rabbitmq",
  SMS = "sms",
}

export const ObjectTypeToLabel = (key: string): string => {
  const map: any = {
    app_jobs: "App Job",
    processing_node: "Processing Node",
    cn_core: "CN Core",
    device: "Device",
    infra_or_external_node: "Infra or External Node",
    client_app: "Client App",
  };

  return map[key];
};

export interface IClassObjectInstance {
  id: string;
  imageId: string;
  imageVersion: string;
  label: string;
  maxReplicaCount?: number;
  minReplicaCount?: number;
  type: string;
  version: string;
  serialNumber?: string;
}

export interface IClassObject {
  type: ObjectType;
  nodeType: NodeLayout;
  id: string;
  label: string;
  endpoints: IEndpoint[] | [];
  supportedPlugins: SupportedPlugin[] | [];
  pluginId: string;
  pluginName: string;
  deviceType: string;
  fwImageVersion: string;
  fwModelDescription: string;
  fwModelVersion: string;
  model: string;
  modelId: string;
  nodeId: string;
  version?: string;
  appIcon?: string;
  appType?: string;
  appUrl?: string;
  instance?: IClassObjectInstance | null;
  dynamicPluginSupport: boolean;
  minReplicaCount?: number | null;
  maxReplicaCount?: number | null;
}

export interface IEdgeData {
  sourceProtocol: string;
  sinkProtocol: string;
}

export const denormalizedNodeTypes = (type: NodeLayout): string => {
  if (type === NodeLayout.SOURCE) {
    return "output";
  }

  if (type === NodeLayout.SOURCE_SINK) {
    return "default";
  }

  return "input";
};

export const denormalizeNodeTypeByType = (node: IClassObject): NodeLayout => {
  let containsInput = false;
  let containsOutput = false;
  node.endpoints.forEach((ep) => {
    if (ep.dataDirection === DataDirection.IN) {
      containsInput = true;
    }

    if (ep.dataDirection === DataDirection.OUT) {
      containsOutput = true;
    }
  });

  if (containsInput && containsOutput) {
    return NodeLayout.SOURCE_SINK;
  } else if (containsInput) {
    return NodeLayout.SOURCE;
  } else if (containsOutput) {
    return NodeLayout.SINK;
  } else {
    return NodeLayout.OTHERS;
  }
};

export const nodeStyleClasses = (type: ObjectType) => {
  switch (type) {
    case ObjectType.DEVICE:
      // grey
      return { colorSolid: "#A5A5A5", colorLight: "#A5A5A533" };
    case ObjectType.CN_CORE:
      return { colorSolid: "#A5A5A5", colorLight: "#A5A5A533" };
    case ObjectType.PROCESSING_NODE:
      // bright blue
      return { colorSolid: "#0070C0", colorLight: "#0070C033" };
    case ObjectType.APP_JOB:
      return { colorSolid: "#0070C0", colorLight: "#0070C033" };
    case ObjectType.INFRA_OR_EXTERNAL_NODE:
      // blue-magenta
      return { colorSolid: "#7030A0", colorLight: "#7030A033" };
    case ObjectType.CLIENT_APP:
      // teal
      return { colorSolid: "#008080", colorLight: "#00808033" };
    default:
      return { colorSolid: "green", colorLight: "#00FF0033" };
  }
};
