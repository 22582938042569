import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "reactflow/dist/style.css";

import "./app.css";

// redux store setup
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store";
import AppRouterSetup from "./pages/Routes";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <AppRouterSetup />
      </BrowserRouter>
    </ReduxProvider>
  </React.StrictMode>
);
