import { Box, IconButton } from "@mui/material";
import React, { PropsWithChildren } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface RuntimeModelFlagValuesProps {
  flowConfigurations: any;
  type: "portal" | "setup";
}

function RuntimeModelFlagValues({
  flowConfigurations,
  type,
}: PropsWithChildren<RuntimeModelFlagValuesProps>) {
  const [expand, setExpand] = React.useState<boolean>(false);
  return (
    <>
      <div
        style={{
          position: "absolute",
          // background: "#F5F5F6",
          borderRadius: "4px",
          border: "2 px solid black",
          top: type === "portal" ? -6 : 100,
          right: 10,
          height: type === "portal" ? "400px" : "300px",
        }}
        className="overflow-y-auto"
      >
        <div className="my-1">
          <Box sx={{ maxWidth: 250 }}>
            <div
              className="py-1 px-2 border-b-2 flex items-center 
              leading-normal font-semibold text-sm bg-sky-200/50"
            >
              <p>Flow Model Configurations</p>
              <p className="pl-6">
                <IconButton
                  aria-label="add"
                  size="small"
                  onClick={() => setExpand(!expand)}
                  color="primary"
                >
                  {expand ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                  )}
                </IconButton>
              </p>
            </div>
            {expand && (
              <div
                style={{
                  background: "#F5F5F6",
                  zIndex: 6,
                  position: "absolute",
                }}
              >
                {flowConfigurations &&
                  flowConfigurations.map((item: any, index: number) => (
                    <div
                      className="px-2 m-1 border border-sky-200"
                      key={item.id + index}
                    >
                      <p className="leading-tight text-sm py-0.5">
                        {item.prompt}:{" "}
                        <strong>{item.currentValue || "-"}</strong>
                      </p>
                    </div>
                  ))}
              </div>
            )}
          </Box>
        </div>
      </div>
    </>
  );
}

export default RuntimeModelFlagValues;
