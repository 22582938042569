import React, { PropsWithChildren, useEffect, useState } from "react";
import { Node } from "reactflow";

import {
  IClassObject,
  nodeStyleClasses,
  ObjectType,
} from "../../models/template-editor-model";
import NodeIcon from "./NodeIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

interface INonInstantiatedProps {
  rawNode: Node<IClassObject>;
}

function RTNonInstantiatedNode({
  rawNode,
}: PropsWithChildren<INonInstantiatedProps>) {
  const { data: node } = rawNode;
  const [nodeBorderColor, setNodeBorderColor] = useState<string>("grey");

  const [colorClass] = useState<{ colorSolid: string; colorLight: string }>(
    nodeStyleClasses(node.type)
  );

  const rtModelFlowNodes = useSelector(
    (state: RootState) => state.setupState.rtModelFlowNodes
  );

  //  for ellipsis the string
  // const truncate = (str: string) => {
  //   return str.length > 20 ? str.substring(0, 19) + "..." : str;
  // };

  useEffect(() => {
    if (rtModelFlowNodes.length) {
      rtModelFlowNodes.forEach((n: string) => {
        if (node.id === n) {
          setNodeBorderColor("green");
        }
      });

      if (!rtModelFlowNodes.includes(node.id)) {
        setNodeBorderColor("grey");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtModelFlowNodes]);

  return (
    <div
      key={node.id}
      className={`cursor-grab text-left flex ${
        nodeBorderColor === "green" ? "border-4" : "border-2"
      } rounded hover:shadow-lg`}
      style={{ borderColor: nodeBorderColor, minHeight: "140px" }}
    >
      <div
        className={`px-2 flex items-center border-r border-${colorClass.colorSolid}-300`}
        style={{ backgroundColor: colorClass.colorLight }}
      >
        <div className="max-w-[60px]">
          <NodeIcon type={node?.type} model={node.model} />
        </div>
      </div>
      <div className="w-full flex flex-col justify-between">
        {/* Description Section */}
        <div className="p-2">
          {node.type === ObjectType.DEVICE && (
            <>
              <p className="font-medium mb-1 break-all">{node.model}</p>
              <div className="text-[12px]">Fw v: {node.fwModelVersion}</div>
              <div className="text-[12px]">{node.deviceType}</div>
            </>
          )}

          {node.type === ObjectType.CN_CORE && (
            <>
              <p className="font-medium mb-1 break-all">{node.model}</p>

              <div className="text-[12px]">Fw v: {node.fwModelVersion}</div>
              <div className="text-[12px]">{node.deviceType}</div>
            </>
          )}

          {node.type === ObjectType.PROCESSING_NODE && (
            <>
              <p className="font-medium mb-2 break-all">
                {node.pluginName || node.model}
              </p>
            </>
          )}

          {node.type === ObjectType.INFRA_OR_EXTERNAL_NODE && (
            <>
              <p className="font-medium mb-2 break-all">{node.label}</p>
              {/* <div className="text-sm">Version: {node.version}</div> */}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RTNonInstantiatedNode;
