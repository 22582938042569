import { Box, Button, IconButton, Snackbar } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import * as api from "../../../../../api/setup-map.api";
import { DisplayEventAggregates, FilterAggregates } from "../model";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface RuntimeModelEventsKPIProps {
  setupTemplateId: string | null;
  runtimeModelId: string | undefined;
  type: "portal" | "setup";
}

function RuntimeModelEventsKPI({
  setupTemplateId,
  runtimeModelId,
  type,
}: PropsWithChildren<RuntimeModelEventsKPIProps>) {
  const [expand, setExpand] = React.useState<boolean>(false);
  const [eventOccurrAgg, setEventOccurrAgg] = useState<FilterAggregates>();
  const [displayEventOccurrAgg, setDisplayEventOccurrAgg] =
    useState<DisplayEventAggregates>({
      states: [],
      eventStats: [],
    });

  const [snackbar, setSnackbar] = React.useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState<string>("");

  useEffect(() => {
    let intervalId: any;
    const fetchData = () => {
      // setIsLoading(true);
      if (runtimeModelId) {
        // event occurrences of this rt model
        const body = {
          runtimeModelIds: [runtimeModelId],
          aggregations: true,
          includeExpired: true,
        };

        api
          .getEventOccurrenceWithFilterBody(body)
          .then((res) => {
            if (res) {
              setEventOccurrAgg(res.aggregations);
              // processEventOccurrenceForDisplay();
            }
          })
          .catch((err) => {
            console.log(err);

            setSnackbarMsg("Something went wrong");
            setSnackbar(true);
          })
          .finally(() => {
            // setIsLoading(false);
          });
      }
    };

    if (runtimeModelId) {
      fetchData();
    }

    intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runtimeModelId]);

  useEffect(() => {
    if (!eventOccurrAgg) return;

    let updatedStates: any = [];
    let updatedEventStats: any = [];

    Object.keys(eventOccurrAgg).forEach((key) => {
      // console.log(key);
      // const value = eventOccurrAgg[key];

      switch (key) {
        case "states":
          const eventStates = [
            "set",
            "acknowledged",
            "escalated",
            "cleared",
            "corrected",
          ];

          if (eventOccurrAgg.states) {
            const newStatesDisplayArr = eventOccurrAgg.states.map((elem) => {
              if (eventStates.includes(elem.key)) {
                eventStates.splice(eventStates.indexOf(elem.key), 1);
              }
              return {
                value: elem.doc_count ?? "-",
                key: elem.key,
                label: `Total ${toTitleCase(elem.label)}`,
              };
            });

            if (eventStates.length) {
              const statesToBeIncluded = eventStates.map((elem) => ({
                value: "-",
                key: elem,
                label: `Total ${toTitleCase(elem)}`,
              }));
              updatedStates.push(...newStatesDisplayArr, ...statesToBeIncluded);
            } else {
              updatedStates.push(...newStatesDisplayArr);
            }
          }

          break;

        case "totalExpiredEvents":
          const tempNestedEventStatsExpired = {
            key: key,
            value: eventOccurrAgg.totalExpiredEvents[0].doc_count,
            label: key
              .split(/(?=[A-Z])/)
              .map((elem) => toTitleCase(elem))
              .join(" "),
          };
          updatedEventStats.push(tempNestedEventStatsExpired);
          break;

        case "eventsExpiringSixtyMinutes":
          const tempNestedEventStatsSixty = {
            key: key,
            value: eventOccurrAgg.eventsExpiringSixtyMinutes[0].doc_count ?? 0,
            label: "Events Expiring Within Sixty Minutes"
              .split(/(?=[A-Z])/)
              .map((elem) => toTitleCase(elem))
              .join(" "),
          };
          updatedEventStats.push(tempNestedEventStatsSixty);
          break;

        case "averageConfidence":
          const tempEventStatsConfidence = {
            key: key,
            value: (eventOccurrAgg.averageConfidence * 100).toFixed(2) + "%", // Format to 2 decimal places and add %
            label: key
              .split(/(?=[A-Z])/)
              .map((elem) => toTitleCase(elem))
              .join(" "),
          };
          updatedEventStats.push(tempEventStatsConfidence);
          break;

        case "averageDuration":
          const tempEventStatsDuration = {
            key: key,
            value: eventOccurrAgg.averageDuration.toFixed(3),
            label: key
              .split(/(?=[A-Z])/)
              .map((elem) => toTitleCase(elem))
              .join(" "),
          };
          updatedEventStats.push(tempEventStatsDuration);
          break;

        default:
          break;
      }
    });

    setDisplayEventOccurrAgg({
      states: updatedStates,
      eventStats: updatedEventStats,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventOccurrAgg]);

  const toTitleCase = (str: string) => {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          // background: "#F5F5F6",
          borderRadius: "4px",
          border: "2 px solid black",
          top: type === "portal" ? -6 : 100,
          right: 260,
          height: type === "portal" ? "400px" : "300px",
        }}
        // className="overflow-y-auto"
      >
        <div className="my-1">
          <Box sx={{ maxWidth: 250 }}>
            <div
              className="py-1 px-2 border-b-2 flex items-center 
              leading-normal font-semibold text-sm bg-sky-200/50"
            >
              <p>Event Occurrences KPIs</p>
              <p className="pl-6">
                <IconButton
                  aria-label="add"
                  size="small"
                  onClick={() => setExpand(!expand)}
                  color="primary"
                >
                  {expand ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                  )}
                </IconButton>
              </p>
            </div>
            {expand && (
              <div
                style={{
                  background: "#F5F5F6",
                  zIndex: 6,
                  position: "absolute",
                }}
                className="py-3"
              >
                <div className="grid grid-cols-1 gap-1 pl-2">
                  {/* states */}
                  {displayEventOccurrAgg &&
                    displayEventOccurrAgg.states.map((state, index) => (
                      <>
                        <div
                          key={state.key + index}
                          className="flex items-center justify-between"
                          style={{ marginTop: "0px" }}
                        >
                          <div className="text-sm whitespace-break-spaces">
                            <p>{state.label || ""}:</p>
                          </div>
                          <div className="text-sm leading-tight pr-2">
                            <b>{state.value || 0}</b>
                          </div>
                        </div>
                        <hr
                          key={`hr-${index}`}
                          className="border-t border-gray-200"
                        />
                      </>
                    ))}

                  {/* event states */}
                  {displayEventOccurrAgg &&
                    displayEventOccurrAgg.eventStats.map(
                      (eventState, index) => (
                        <>
                          <div
                            key={eventState.key + index}
                            className="flex items-center justify-between"
                            style={{ marginTop: "0px" }}
                          >
                            <div className="text-sm whitespace-break-spaces">
                              <p>{eventState.label || ""}:</p>
                            </div>
                            <div className="text-sm leading-tight pr-2">
                              <b>{eventState.value || 0}</b>
                            </div>
                          </div>
                          <hr
                            key={`hr-${index}`}
                            className="border-t border-gray-200"
                          />
                          {/* <div
                            className="text-sm"
                            key={eventState.key + index}
                            style={{ marginTop: "-12px" }}
                          >
                            <p>{eventState.label || ""}</p>
                          </div>
                          <div
                            className="text-sm"
                            key={eventState.value + index}
                            style={{ marginTop: "-12px" }}
                          >
                            <b>{eventState.value || 0}</b>
                          </div> */}
                        </>
                      )
                    )}
                </div>
              </div>
            )}
          </Box>
        </div>
      </div>

      {/* Snackbar */}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={3000}
        message={snackbarMsg}
        open={snackbar}
        onClose={() => setSnackbar(false)}
        action={
          <>
            <Button color="secondary" onClick={() => setSnackbar(false)}>
              <CloseIcon />
            </Button>
          </>
        }
      />
    </>
  );
}

export default RuntimeModelEventsKPI;
