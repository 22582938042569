import { ISetupDetailResponse } from "../models/setup-detail.model";
import { get, post, put, del } from "../utils/axios.utils";

export const createSetup = (requestPayload: any): Promise<any> =>
  post<any>(`/setups-services/v1/setups`, requestPayload);

export const instantiateSetupNode = (
  setupId: string,
  requestPayload: any
): Promise<any> =>
  post<any>(
    `/setups-services/v1/setups/${setupId}/instantiate-node`,
    requestPayload
  );

export const instantiateMultiAppProcessors = (
  setupId: string,
  requestPayload: any
): Promise<any> =>
  post<any>(
    `/setups-services/v1/setups/${setupId}/instantiate-multiple-nodes`,
    requestPayload
  );

export const updateSetupDetail = (
  setupId: string,
  requestPayload: any
): Promise<any> =>
  put<any>(`/setups-services/v1/setups/${setupId}`, requestPayload);

export const getSetupDetail = (
  setupId: string
): Promise<ISetupDetailResponse> =>
  get<any>(`/setups-services/v1/setups/${setupId}`);

export const deploySetup = (setupId: string): Promise<any> =>
  post<any>(`/setups-services/v1/setups/${setupId}/deploy`);

export const stopSetup = (setupId: string): Promise<any> =>
  post<any>(`/setups-services/v1/setups/${setupId}/stop`);

export const purgeStreamsData = (setupId: string): Promise<any> =>
  post<any>(`/setups-services/v1/setups/${setupId}/purge-queue`);

export const getSetupState = (setupId: string): Promise<any> =>
  get<any>(`/setups-services/v1/setups/${setupId}/state`);

// returns paginated response (look for records)
export const getDeviceList = (
  fwModelId: string,
  modelId: string
): Promise<any> =>
  get<any>(
    `/devices-services/v3/devices?firmwareModelId=${fwModelId}&modelId=${modelId}&isSetupMapped=false&size=150`
  );

// returns paginated response (look for records)
export const getClusterNodeList = (
  fwModelId: string,
  modelId: string
): Promise<any> =>
  get<any>(
    `/cn-cores-services/v3/cn-cores?firmwareModelId=${fwModelId}&modelId=${modelId}&size=150`
  );

// returns paginated response (look for records)
export const getProcessingImageList = (
  modelId: string,
  isActive: boolean
): Promise<any> =>
  get<any>(
    `/processing-nodes-services/v3/app-processor-models/${modelId}/images?size=150&isActive=${
      isActive ? 1 : 0
    }`
  );

// returns paginated response (look for records)
export const getProcessingFilesList = (
  pluginId: string,
  modelId: string
): Promise<any> =>
  get<any>(
    `/processing-nodes-services/v3/app-processor-plugins/${pluginId}/package-files?size=150&modelId=${modelId}&isActive=1`
  );

// returns supported affinity tags list
export const getAffinityTagsList = (
  pluginId: string,
  modelId: string
): Promise<any> =>
  get<any>(
    `/processing-nodes-services/v3/app-processor-models/supported-affinity-tags?pluginId=${pluginId}&modelId=${modelId}`
  );

// returns paginated response (look for records)
export const getAppJobImageList = (modelId: string): Promise<any> =>
  get<any>(
    `/job-nodes-services/v3/app-job-models/${modelId}/packages?size=150`
  );

export const getNodeStatus = (setupId: string, nodeId: string): Promise<any> =>
  get<any>(`/setups-services/v1/setups/${setupId}/nodes/${nodeId}/state`);

export const getSetupSchema = (
  setupId: string,
  requestPayload: { dataSchema: string }
): Promise<any> =>
  post<any>(
    `/setups-services/v1/setups/${setupId}/nodes-connection-details`,
    requestPayload
  );

// leave device from setup
export const leaveDeviceFromSetup = (deviceId: string): Promise<any> =>
  post<any>(`/devices-services/v3/devices/${deviceId}/leave-setup`);

// leave CN-core from setup
export const leaveCnCoreFromSetup = (
  cnCoreId: string,
  setupId: string
): Promise<any> =>
  post<any>(
    `/cn-cores-services/v3/cn-cores/devices/${cnCoreId}/setup/${setupId}/leave-setup`
  );

// ***************  Runtime model api's ****************

// Runtime models list
export const getRuntimeModelsList = (setupTemplateId: string): Promise<any> =>
  get<any>(
    `/runtime-models-services/v1/runtime-models?setupTemplateId=${setupTemplateId}&flowsRequired=true`
  );

// Runtime model
export const getRuntimeModelDetail = (
  runtimeModelId: string,
  setupTemplateId?: string | null
): Promise<any> => {
  let url = `/runtime-models-services/v1/runtime-models/${runtimeModelId}?flowsRequired=true`;

  if (setupTemplateId) {
    url += `&setupTemplateId=${setupTemplateId}`;
  }

  return get<any>(url);
};

// Operational Data Flow
export const getOperationalDataFlow = (
  operationalDataId: string
): Promise<any> => {
  const payload = {
    id: operationalDataId,
    flowsRequired: true,
  };
  let url = `/files-services/v3/operational-metadata`;

  return post<any>(url, payload);
};

// training-frameworks list
export const getTrainingFrameworksList = (): Promise<any> =>
  get<any>(`/runtime-models-services/v1/training-frameworks`);

// training-workflows list
export const getTrainingWorkflowsList = (fmId: string): Promise<any> =>
  get<any>(`/runtime-models-services/v1/training-workflows/${fmId}`);

// fetching artifacts
export const getArtifactsList = (modelId: string): Promise<any> =>
  get<any>(
    `/runtime-models-services/v1/runtime-model-retrain-artifacts/${modelId}`
  );

// deploy artifacts
export const deployArtifacts = (
  artifactId: string,
  requestPayload: any
): Promise<any> =>
  post<any>(
    `/runtime-models-services/v1/runtime-model-retrain-artifacts/${artifactId}/deploy`,
    requestPayload
  );

// delete artifact
export const deleteArtifact = (artifactId: string): Promise<any> =>
  del<any>(
    `/runtime-models-services/v1/runtime-model-retrain-artifact/${artifactId}`
  );

// delete model
export const deleteModel = (modelId: string): Promise<any> =>
  del<any>(`/runtime-models-services/v1/runtime-models/${modelId}`);

// fetching artifact resources
export const getArtifactsResources = (modelId: string): Promise<any> =>
  get<any>(
    `/runtime-models-services/v1/runtime-model-retrain-artifacts/${modelId}/resources`
  );

// update runtime model info
export const updateModelArtifactsInfo = (
  modelId: string,
  requestPayload: any
): Promise<any> =>
  put<any>(
    `/runtime-models-services/v1/runtime-models/${modelId}`,
    requestPayload
  );

// trigger-retraining
export const triggerRetraining = (modelId: string): Promise<any> =>
  post<any>(
    `/runtime-models-services/v1/runtime-models/${modelId}/trigger-retraining`
  );

// short Qr code data
export const shortQrCodeData = (requestPayload: any): Promise<any> =>
  post<any>(`/shortener-services/v1/short-data`, requestPayload);

// Fetch runtime model listing
export const fetchRuntimeModelEvents = (
  runtimeModelId: string,
  isExpired: boolean = false
): Promise<any> =>
  post<any>(`/events-predictions-services/v3/events`, {
    runtimeModelIds: [runtimeModelId],
    includeExpired: isExpired,
  });

// event occurrences
export const getEventOccurrenceWithFilterBody = (
  requestPayload: any
): Promise<any> => {
  let body = requestPayload || {};
  return post<any>(`/events-predictions-services/v3/events`, body);
};
