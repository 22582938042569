import { get, post, put } from "../utils/axios.utils";
import {
  ITemplateRequestPayload,
  ITemplateResponsePayload,
} from "../models/template-detail.model";

export const createTemplate = (
  requestPayload: ITemplateRequestPayload
): Promise<any> => post<any>(`/setups-services/v1/templates`, requestPayload);

export const updateTemplate = (
  templateId: string,
  requestPayload: ITemplateRequestPayload
): Promise<any> =>
  put<any>(`/setups-services/v1/templates/${templateId}`, requestPayload);

export const getTemplateDetail = (
  templateId: string
): Promise<ITemplateResponsePayload> =>
  get<any>(`/setups-services/v1/templates/${templateId}`);
